import { config } from './config';

/**
 * Generates a string with multiple domain patterns into a list of regex
 * @param {string} domains  The list of domains divided by commas (,)
 * @returns List of domains regex
 */
const getTraceUrls = (domains = ''): RegExp[] | undefined => {
  if (!domains) {
    return undefined;
  }
  const domainList = domains?.split(',') || [];
  return domainList.map((url) => new RegExp(url));
};

/**
 * Initialize the Coralogix RUM monitoring
 */
export const initMonitoring = async (): Promise<void> => {
  if (!config.CORALOGIX.isEnabled) {
    return;
  }

  const { CoralogixRum } = await import('@coralogix/browser'); // Workaround as Coralogix doesn't have support for modules or transpiling
  if (CoralogixRum.isInited) {
    // Check if Coralogix is already running
    return;
  }

  const domains = getTraceUrls(config.CORALOGIX.traceDomains);
  const ignoreUrls = getTraceUrls(config.CORALOGIX.traceIgnoredDomains);

  CoralogixRum.init({
    application: config.CORALOGIX.applicationName,
    coralogixDomain: 'EU2',
    debug: config.CORALOGIX.debug,
    environment: config.CORALOGIX.environment || 'development',
    ignoreErrors: [],
    ignoreUrls,
    public_key: config.CORALOGIX.authenticationToken,
    sessionConfig: {
      alwaysTrackSessionsWithErrors: config.CORALOGIX.trackSessionsWithErrors,
    },
    traceParentInHeader: {
      enabled: config.CORALOGIX.traceHeaderEnabled,
      options: {
        allowedTracingUrls: domains,
        propagateTraceHeaderCorsUrls: domains,
      },
    },
    version: config.CORALOGIX.appVersion,
  });
};
